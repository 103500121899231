import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Tooltip,
  Row,
  Col,
  Modal,
  InputNumber,
  Select,
  Checkbox,
  DatePicker,
  message,
  Spin,
} from "antd";
import "antd/dist/antd.css";
import {
  AppstoreAddOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import moment from 'moment';
import PassesData from "../tableData/PassesData";
import CommonApis from "../api/CommonApis";

export default function SocialPasses(props) {
  const { Title, Text } = Typography;
  const { Option } = Select;

  useEffect(() => {
    loadPassData();
  }, []);

  const [passData, setPassData] = useState([]);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [passValue, setPassValue] = useState(1);
  const [passPrice, setPassPrice] = useState(0);
  const [inputPriceDisabled, setInputPriceDisabled] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [expireData, setExpireDate] = useState("");
  const [platform, setPlatform] = useState("");
  const [objective, setObjective] = useState("");
  const [freePass, setFreePass] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [passSizeText, setPassSizeText] = useState('')
  const [selected, setSelected] = useState(""); 

  const loadPassData = () => {
      setPassSizeText('loading social passes...')
      setPassData([])
    setLoadingData(true);
    CommonApis.getAllPasses()
      .then((res) => {
        if (res.data === null) {
          console.log("error loading data!");
        }

        let index = 0;
        res.data.map((user) => {
          passData[index] = user;
          passData[index].passPrice = "$" + user.passPrice;
          // console.log(`index[${index}], ${JSON.stringify(dashboardData[index])}`)
          index++;
        });
        console.log(passData);
        setPassData(passData)
        setLoadingData(false);
        setPassSizeText(`${passData.length} social pass found.`)
      })
      .catch((err) => {});
  };
  function clearAllfields() {
    setPlatform("");
    setObjective("");
    setPassValue(1);
    setPassPrice(1);
    setFreePass(false);
  };
  const buildCreationModal = () => {
    clearAllfields();
    setShowCreationModal(true);
  };
  const handlePassPlatformChange = (event) => {
    console.log(`selected platform: ${event.target.value}`);
    setPlatform(event.target.value);
    setSelected(event.target.value);
  };

  const handlePassObjectiveChange = (value) => {
    setObjective(value);
  };
  const handlePassValueChange = (value) => {
    setPassValue(value);
    console.log(value);
  };

  const handlePassPriceValueChange = (value) => {
    setPassPrice(value);
    console.log(`new pass price: ${value}`);
  };
  const handleNewPassOk = () => {
    if (platform === "") {
      message.error("Platform field is required");
      return;
    }
    if (objective === "") {
      message.error("Objective field is required");
      return;
    }

    console.log(
      `platform: ${platform}, objective: ${objective}, points: ${passValue}, price: ${passPrice}`
    );
    if (showDate) {
      console.log(`expire date: ${expireData}`);
    }

    if (passPrice <= 0 && !freePass) {
      message.error(
        "Pass price must be $1 or more, or you can set it as Free Pass."
      );
      return;
    }
    setModalLoading(true);

    let passModal = {
      platform: platform,
      objective: objective,
      passValue: passValue,
      passPrice: passPrice,
      expirationDate: expireData,
    };

    CommonApis.createNewPass(passModal)
      .then((res) => {
        console.log(res.data);
        setModalLoading(false);
        setShowCreationModal(false);

        loadPassData();
        message.success("Social Pass created successfully");
      })
      .catch((err) => {
        console.log(err);
        setModalLoading(false);
        setShowCreationModal(false);

        message.error("Error creating pass !");
      });
  };

  const handleCancel = () => {
    clearAllfields();
    setShowCreationModal(false);
  };

  const onFreePassChange = (e) => {
    if (e.target.checked) {
      setInputPriceDisabled(true);
      setPassPrice(0);
      setShowDate(true);
      setFreePass(true);
    } else {
      setInputPriceDisabled(false);
      setShowDate(false);
      setExpireDate("");
      setFreePass(false);
    }
  };

  const onRefresh = () => {
      loadPassData()
  }
  const onDateChanged = (date, dateString) => {
    console.log(date, dateString);
    setExpireDate(dateString);
  };

  
  return (
    <div>
      <Title>Manage Social Passes</Title>

      <div>
        <Row className="social-pass-size">
          <Col span={12}>
            <h3>
              {passSizeText}
            </h3>
          </Col>
          <Col span={12}>
            <Tooltip title="Add new Social Pass">
              <Button
                className="social-apps-add-button"
                type="primary"
                shape="circle"
                size="large"
                onClick={buildCreationModal}
                icon={<AppstoreAddOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>

        

        
        <Spin spinning={loadingData}>
          <div className="passes-table-container">
          <Row>
            <Col>
            <Title level={3}>Table of Passes</Title>
            </Col>

            <Col>
            <Tooltip title="reload table of Social passes">
              <Button className="social-apps-refresh-button" shape="circle" icon={<ReloadOutlined />} onClick={onRefresh}/>
            </Tooltip>
            </Col>
          </Row>
            

            {!loadingData && <PassesData data={passData} handleParentFun={loadPassData} />}
            <Modal
              title="Add New Social Pass"
              visible={showCreationModal}
              onOk={handleNewPassOk}
              confirmLoading={modalLoading}
              onCancel={handleCancel}
            >
              <Row>
                <Col span={10}>
                  <p>Platform:</p>
                  <Select
                    required
                    // defaultValue="facebook"
                    style={{ width: "100%" }}
                    value={platform}
                    onChange={handlePassPlatformChange}
                  >
                    <Option value="facebook">Facebook</Option>
                    <Option value="youtube">YouTube</Option>
                     
                    <Option value="twitter" disabled>
                      Twitter
                    </Option>
                    <Option value="instagram" disabled>
                      Instagram
                    </Option>
                    
                  </Select>
                </Col>
                <Col span={10} className="modal-right-view">
                  <p>Objective:</p>
                  <Select
                    //   defaultValue="likes"
                    style={{ width: "100%" }}
                    onChange={handlePassObjectiveChange}
                    value={objective}
                  >
                    <Option value="likes">Like or Reacton</Option>
                    <Option value="follow">
                      Follow
                    </Option>
                    <Option value="subsribe">
                      Subscribe
                    </Option>
                  </Select>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <br />
                  <p>Pass Value: (ex: 100 Likes)</p>
                  <InputNumber
                    min={0}
                    value={passValue}
                    defaultValue={0}
                    onChange={handlePassValueChange}
                  />
                </Col>
                <Col span={12}>
                  <br />
                  <p>Pass Price: (ex: $10)</p>

                  <InputNumber
                    min={0}
                    max={999}
                    disabled={inputPriceDisabled}
                    defaultValue={1}
                    value={passPrice}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={handlePassPriceValueChange}
                  />
                </Col>
              </Row>
              <br />
              <Checkbox checked={freePass} onChange={onFreePassChange}>Free Pass &#160;</Checkbox>
              <Tooltip title="Free pass requires an expiration date">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>

              {showDate && (
                <div style={{ marginTop: "15px" }}>
                  <b />
                  <b />
                  <p>Select pass expiration date:</p>
                  <DatePicker onChange={onDateChanged} 
                  />
                  <p>
                    <Text type="secondary">
                      *When this date is expired this pass will disappear from
                      LikeStory android app.
                    </Text>
                  </p>
                </div>
              )}
            </Modal>
          </div>
        </Spin>
      </div>
    </div>
  );
}
