import React, { useState, useEffect } from "react";
import {
  Badge,
  Avatar,
  Row,
  Col,
  Table,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Typography,
  message,
  Input,
  dataset
} from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import CommonApis from "../api/CommonApis";

export default function AdsData(props) {

    
  const { Option } = Select;
  const { Title } = Typography;
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [platform, setPlatform] = useState('');
  const [packageName, setPackageName] = useState('');
  const [adBanner, setAdBanner] = useState('');
  const [adInterstitial, setAdInterstitial] = useState('');
  const [adNative, setAdNative] = useState('');
  const [adPaused, setPausedAd] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [adId, setAdId] = useState(0);

  useEffect(() => {
    setDataset(props.data);
    console.log("received data: " + dataset);
  }, [props.data, dataset]);


  const buildUpdateModal = (record) => {
    clearAllFields()
    setAdId(record.id)
    setPlatform(record.serviceName)
    setPackageName(record.packageName)
    setPausedAd(record.paused)
    setAdBanner(record.bannerAd)
    setAdInterstitial(record.interstitialAd)
    setAdNative(record.nativeAd)

    setShowUpdateModal(true)
  }

  const handleUpdateAdOk = () => {
    if (!adPaused) {
        if (adBanner === '') {
          message.warning("Please provide banner Ad !")
          return;
        }
        if (adInterstitial === '') {
          message.warning("Please provide interstitial Ad !")
          return;
        }
      }
      setModalLoading(true);


      let adModal = {
        id: adId,
        packageName : 'com.likestoryeg.android',
        paused: adPaused,
        serviceName: 'admob',
        bannerAd : adBanner,
        interstitialAd : adInterstitial,
        nativeAd : adNative
      }

      CommonApis.updateAd(adModal)
        .then(res => {
          setModalLoading(false);
          setShowUpdateModal(false);
          message.success("Ad updated successfully !");
          clearAllFields();
          //loadAds();
        })
        .catch(err => {
          console.log(err);
          setModalLoading(false);
          setShowUpdateModal(false);

          message.error("Error updating Ad !");
        })


  }

  const handleCancel = () => {
    clearAllFields();
    setShowUpdateModal(false);
  }

  const onBannerAdChange = (event) => {
    setAdBanner(event.target.value)
}

const onInterstitialAdChange = (event) => {
    setAdInterstitial(event.target.value)
}

const onNativeAdChange = (event) => {
    setAdNative(event.target.value)
}

const onAdSwith = () => {
  setPausedAd(!adPaused)
}

const clearAllFields = () => {
  setAdBanner('')
  setAdInterstitial('')
  setAdNative('')
  setPausedAd(false);
}

  const columns = [
    {
      title: "Platform",
      dataIndex: "serviceName",
      key: "servicename",
      width: "2%",
      render: (objective) => (
        <Avatar
          size={48}
          src={process.env.PUBLIC_URL + "/images/" + objective + ".png"}
          alt={objective}
        />
      ),
    },
    {
      title: "status",
      dataIndex: "paused",
      key: "paused",
      render: (record) => !record ? (
        <Badge color={"green"} text="Active" />
      ) : (
        <Badge color={"red"} text="Paused" />
      ),
    },
    {
        title: "Banner ID",
        dataIndex: "bannerAd",
        key: "bannerad",
    },
    {
        title: "Interstitial ID",
        dataIndex: "interstitialAd",
        key: "interstitialad",
    },
    {
        title: "Native ID",
        dataIndex: "nativeAd",
        key: "nativead",
    },
    {
        title: "Actions",
        key: "operation",
        render: (record) => 
        dataset.length >= 1 ? (
         <div>
             <Popconfirm title="Sure to update?" onConfirm={() => buildUpdateModal(record)}>
                <a>Update Ad</a>
              </Popconfirm>
         </div>
        ) : null,
      },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={props.data} rowKey="id" />

      <Modal
              title="Update Ad"
              visible={showUpdateModal}
              onOk={handleUpdateAdOk}
              confirmLoading={modalLoading}
              onCancel={handleCancel}>

                <Row>
                <Col span={10}>
                  <p>Platform:</p>
                  <Select
                    required
                    defaultValue={platform}
                    style={{ width: "100%" }}>
                    <Option value="admob">admob</Option>
                    <Option value="facebook" disabled>Facebook Audience Network</Option>
                  </Select>
                </Col>

                <Col span={10} className="modal-right-view">
                  <p>Package Name:</p>
                  <Select
                    defaultValue={packageName}
                    style={{ width: "100%" }}>
                    <Option value="likestory">com.likestoryeg.android</Option>
                  </Select>
                </Col>
                </Row>

              <br />
              <Input value={adBanner} placeholder="Banner Ad" onChange={onBannerAdChange}/>
              <br />
              <br />
              <Input value={adInterstitial} placeholder="Intertitial Ad" onChange={onInterstitialAdChange} />
              <br />
              <br />
              <Input value={adNative} placeholder="Native Ad" onChange={onNativeAdChange}/>
              <br />
              <br />
              <span>Pause Ads : </span><Switch checked={adPaused} onChange={onAdSwith}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />} />
            
          </Modal>

      
    </div>
  );
}
