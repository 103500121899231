import React, { useState, useEffect } from "react";
import { Table, Input, Button, Avatar, Modal, InputNumber, message, Select } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, StopOutlined } from "@ant-design/icons";
import CommonApis from '../api/CommonApis';

export default function UsersData(props) {

 // setHasData(true)
  /*
  const data = 
*/
  const [dataset, setDataset] = useState([
  {
    userId: "",
    email: "",
    name: "",
    picture: "",
    customer: "",
    totalPurchases: "",
    userPoints: "",
    totalActivePosts: "",
    totalInactivePosts: "",
  },
  ])
  const update = {
    userId: "",
    newValue: ""
  };

  const { Option } = Select;

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [userPoints, setUserPoints] = useState(0);
  const [currentRowIndex, setCurrentRowIndex] = useState();
  const [currentRecord, setCurrentRecord] = useState({})
  const [blockModalVisible, setBlockModalVisible] = useState(false)
  const [blockModalLoading, setBlockModalLoading] = useState(false)
  const [blockStatus, setBlockStatus] = useState('unblock')
  let searchInput;

  useEffect(() => {
    setDataset(props.data)
  }, [props.data, dataset])
  
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setModalVisible(false);
  };

  function userPointsValueChange(value) {
    setUserPoints(value);
    console.log(`new values: ${value}`);
  }

  const showModal = (record, userPoints, rowIndex) => {
    setCurrentRecord(record)
    setCurrentRowIndex(rowIndex);
    console.log(userPoints);
    setModalVisible(true);
    setUserPoints(userPoints);
  };

  const showBlockUserModal = (record) => {
      setBlockModalVisible(true)
  }

  const handleModalChange = () => {
    setModalLoading(true);
   // console.log(currentRecord)
    
    update.userId = currentRecord.userId
    console.log(currentRecord.userId)
    update.newValue = userPoints
    let thisProps = {...props}
    CommonApis.updateUserPoints(update)
    .then(res => {
      message.success("User points updated successfully.");
        console.log(res);
        dataset[currentRowIndex] = res.data.userPoints;
        setDataset(dataset)
        thisProps.handleParentFun()
        
        setModalLoading(false);
        handleCancel()
    })
    .catch(err => {
        console.log(err);
        message.error("Error updating user points !");
        setModalLoading(false);
    })
    
  };

  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const blockValueChange = (value) => {
    setBlockStatus(value)
  }



  const columns = [
    {
      title: "",
      dataIndex: "picture",
      key: "pic",
      width: "2%",
      render: (theImageURL) => <Avatar src={theImageURL} />,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "userId",
      ...getColumnSearchProps("email"),
    render: (email, row) => <a href={`/user/details/${row.userId}/`} target="_blank">{email}</a> 
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Total Points",
      dataIndex: "userPoints",
      key: "totalpoints",
      render : (userPoints) => userPoints > 0 ? (<span style={{color: "green"}}>{userPoints}</span>) : (<span style={{color: "green"}}>{userPoints}</span>)
    },
    {
      title: "Total Purchases",
      dataIndex: "totalPurchases",
      key: "totalpurchases",
    },
    {
      title: "Total Active Posts",
      dataIndex: "totalActivePosts",
      key: "totalactiveposts",
    },
    {
      title: "Total Inactive Posts",
      dataIndex: "totalInactivePosts",
      key: "totalinactiveposts",
    },
    {
      title: "Actions",
      key: "operation",
      render: (record, rowIndex) => 
      <div>
        <Button type="dashed" onClick={() => showModal(record, record.userPoints, rowIndex)}>Update Points</Button>

        <Button type="text" icon={<StopOutlined />} danger onClick={() => showBlockUserModal(record)}>
            Block
          </Button>
      </div>
      
    },
  ];

  return (
    <div>

      <Table
        columns={columns}
        dataSource={props.data}
        rowKey="userId"
      />
    
      <Modal
        title="Update User Points"
        visible={modalVisible}
        onOk={handleModalChange}
        confirmLoading={modalLoading}
        onCancel={handleCancel}
      >
        <p>New user points value: (ex: 100)</p>
        <InputNumber
          min={0}
          value={userPoints}
          defaultValue={0}
          onChange={userPointsValueChange}
        />
      </Modal>


      <Modal
        title="Block User"
        visible={blockModalVisible}
        onOk={handleModalChange}
        confirmLoading={blockModalLoading}
        onCancel={handleCancel}>
          <Select
            defaultValue="unblock"
            style={{ width: 220 }}
            value={blockStatus}
            onChange={blockValueChange}
          >
            <Option value="unblock">Unblock</Option>
            <Option value="block">Block</Option>
            <Option value="tempblock">Temporary Block</Option>
          </Select>
          <br/>
          <br/>
          <p>Temporary Block period: </p>
          <Select
            defaultValue="unblock"
            style={{ width: 220 }}
            value={blockStatus}
            onChange={blockValueChange}
          >
            <Option value="1d">Unblock</Option>
            <Option value="block">Block</Option>
            <Option value="tempblock">Temporary Block</Option>
          </Select>
      </Modal>
    </div>
  );
}
