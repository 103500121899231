import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import CommonApis from '../api/CommonApis';

export default function AuthenticatedRoute(props) {

    if(CommonApis.isAuthenticatedUser()){
        return (
            <Route {...props}></Route>
        )
        
    } else {
        return (
            <Redirect to="/login"></Redirect>
        )
    }
}
