import React, { useState, useEffect } from "react";

import { Spin, Typography, Tooltip, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import CommonApis from "../api/CommonApis";
import "./styles.css";

import ArchivedData from "../tableData/ArchivedData";

export default function ArchivedLinks(props) {
  const { Title } = Typography;

  const [userPosts, setUserPosts] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getArchivedLinks();
  }, []);

  const onArchivedLinksRefersh = () => {
    getArchivedLinks();
    console.log("called from child");
  };

  const getArchivedLinks = () => {
    console.log("-> getting links for ");
    setLoadingData(true);
    CommonApis.getArchivedLinks()
      .then((res) => {
        if (res.data === null) {
          message.error("error getting data at this time!");
          return;
        }
        console.log("archived: " + res.data)
        setUserPosts(res.data);
        setLoadingData(false);
      })
      .catch((err) => {
        if (err.response.status !== null) {
          if (err.response.status === 404) {
            // user not found
            // setUserNotFoud(true);
          }
        }
      });
  };

  return (
    <div>
      <Title>Archived Links</Title>

      <Spin spinning={loadingData}>
        <div className="data-table">
          <p>
            Tablel of links: &#160;
            <Tooltip title="All archived links are listed below">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          </p>

          {!loadingData && (
            <ArchivedData data={userPosts} handleParentFun={onArchivedLinksRefersh} />
          )}
        </div>
      </Spin>
    </div>
  );
}
