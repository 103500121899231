import React, { useState, useEffect } from "react";
import {
  Layout,
  Spin,
  Typography,
  Upload,
  message,
  Progress,
  Select,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "../../constants/Constants";
import "./styles.css";
import CommonApis from "../api/CommonApis";
import ReleaseData from "../tableData/ReleaseData";

export default function AppRelease(props) {
  const { Title } = Typography;
  const { Dragger } = Upload;
  const { Option } = Select;

  useEffect(() => {
    getReleases();
  }, []);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [releaseData, setReleaseData] = useState([]);
  const [hasReleaseData, setHasReleaseData] = useState(false);
  const [liveRelease, setLiveRelease] = useState("");
  const [loadingReleaseData, setLoadingReleaseData] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [progressStatus, setProgressStatus] = useState("active");
  const getReleases = () => {
    setLoadingReleaseData(true)
    CommonApis.getReleases()
      .then((res) => {

        console.log(res.data);
        setReleaseData(res.data)
        res.data.map((rel) => {
          if (rel.live) {
            setLiveRelease(rel.versionCode);
      //      setActiveRelease(rel.)
          }
        });
        setHasReleaseData(true);
        setLoadingReleaseData(false)
      })
      .catch((err) => {
        setLoadingReleaseData(false)
      });
  };
  const uploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: ".apk,.aab",
    action: `${API_BASE_URL}/web/uploadApk`,
    onChange(info) {
      console.log(info);
      const { status, percent } = info.file;
      if (status !== "uploading") {
        setUploadingFile(true);
        //  console.log(info.file, info.fileList);
      }
      if (status === "uploading") {
        setUploadProgress(~~percent);
   //      console.log("upload percentage1 2: " + info.file.percent)
      }
      /*
      if (info.xhr.status != null || info.file.xhr.status > 0) {
        if (info.xhr.status === 208) {
          message.error(`${info.response}`);
          setUploadingFile(false);
          return;
        }
      }*/

      if (status === "done") {
        //   console.log(info.file.response)
        if (info.file.xhr.status === 208) {
          message.error(`${info.file.xhr.response}`);
          setUploadingFile(false);
          return;
        }

        if (info.file.xhr.status === 200) {
          message.success("New app release successfully uploaded.");
          setUploadingFile(false);
          getReleases();
          setShowUploadProgress(false);
          setProgressStatus("active");
          return;
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setUploadingFile(false);
        setProgressStatus("exception")
      }
    },
  };

  function beforeUpload(file) {
    setUploadingFile(true);
    setShowUploadProgress(true);
    console.log(file);
    const isApk = file.type === "application/vnd.android.package-archive";
    if (!isApk) {
      message.error("You can only upload APK file!");
      setUploadingFile(false);
    }
    const isLt50M = file.size / 1024 / 1024 < 50;
    if (!isLt50M) {
      message.error("Apk must be smaller than 50MB!");
      setUploadingFile(false);
    }
    return isApk && isLt50M;
  }

  const onActiveReleaseRefresh = () => {
    getReleases()
  }
  return (
    <div>
      <Title>Manage App Release</Title>
      <br />
      <br />
      <Spin spinning={uploadingFile}>
        <Dragger
          style={{ marginLeft: "5%", width: "90%", display: "flex" }}
          {...uploadProps}
          beforeUpload={beforeUpload}
          headers={{ Authorization: CommonApis.getUserToken() }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag Apk file to this area to upload
          </p>
          <p className="ant-upload-hint">
            You must upload only <b>.apk</b> to Server. Any other file types
            will be rejected <b>MAX 50MB</b>.
          </p>
        </Dragger>
      </Spin>
      <br/>
      {showUploadProgress && (
            <Progress style={{ marginLeft: "5%", width: "90%", display: "flex" }}
              percent={uploadProgress} status={progressStatus} />
          )}
      
      <br />
      <br />
      <Spin spinning={loadingReleaseData}>
      <span >Published release: Version Code: <b> {liveRelease}  </b></span>
      <ReleaseData data={releaseData} handleParentFun={onActiveReleaseRefresh}/>
      </Spin>
    </div>
  );
}
