import React, { useState, useEffect } from "react";
import {
  Table,
  Badge,
  Button,
  Avatar,
  Modal,
  InputNumber,
  message,
  Select,
  Checkbox,
  Tooltip,
  DatePicker,
  Typography,
  Popconfirm,
} from "antd";
import moment from 'moment';
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import CommonApis from "../api/CommonApis";
export default function PassesData(props) {

  const { Option } = Select;
  const { Title, Text } = Typography;
  const dateFormat = 'YYYY/MM/DDTHH:MM:SS';
  const [dataset, setDataset] = useState([]);
  const [passValue, setPassValue] = useState(0);
  const [passPrice, setPassPrice] = useState(0);
  const [inputPriceDisabled, setInputPriceDisabled] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [expireData, setExpireDate] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [freePass, setFreePass] = useState("");
  const [platform, setPlatform] = useState("");
  const [objective, setObjective] = useState("");
  const [passIndex, setPassIndex] = useState(0)

  useEffect(() => {
    setDataset(props.data);
    console.log("received data: " + dataset);
  }, [props.data, dataset]);


  const buildUpdateModal = (passData) => {

      setPassIndex(passData.id)

      setPassValue(passData.passValue)
      setPassPrice(passData.passPrice.substr(1))
      setPlatform(passData.platform)
      setObjective(passData.objective)

      if(passData.passPrice === '$0' || passData.passPrice === '$0.0' || passData.passPrice === '0' || passData.passPrice === '0.0'){
          setExpireDate(passData.expirationDate)
          setFreePass(true)
          setShowDate(true)
          setInputPriceDisabled(true)
      } else {
          setFreePass(false)
          setShowDate(false)
          setInputPriceDisabled(false)
      }

      setShowUpdateModal(true)

  };

  const handlePassValueChange = (value) => {
      setPassValue(value)
  };
  const handlePassPriceValueChange = (value) => {
      setPassPrice(value)
    console.log(`new pass value: ${value}`);
  };
  const handleUpdatePassOk = () => {

    if(passPrice <= 0 && !freePass){
        message.error('Pass price must be $1 or more, or you can set it as Free Pass.')
        return;
    }
      console.log(passIndex);

      if(passIndex < 0){
          message.error('cannot update null object!')
          return;
      }
      let passModal = {
        id: passIndex,
        platform: platform,
        objective: objective,
        passValue: passValue,
        passPrice: passPrice,
        expirationDate: expireData,
      };
      setModalLoading(true)
      let thisProps = {...props}
      CommonApis.updatePass(passModal)
      .then((res) => {
          console.log(res.data.id)
            message.success('Social pass updated successfully')

            console.log(JSON.stringify(dataset))

           // dataset[res.data.id] = res.data

           thisProps.handleParentFun()
            console.log(dataset[res.data.id])
            
          //  setDataset(dataset)

            setModalLoading(false)
            console.log('closing...')
            handleCancel()
        
      })
      .catch(() => {
        message.error('error updating pass')
       
      })

  };



  const handleCancel = () => {
    setShowUpdateModal(false)
    clearAllfields()
  };

  const onFreePassChange = (e) => {
    if (e.target.checked) {
      setInputPriceDisabled(true);
      setPassPrice(0);
      setShowDate(true);
      setFreePass(true);
    } else {
      setInputPriceDisabled(false);
      setShowDate(false);
      setExpireDate("");
      setFreePass(false);
    }
  };

  const clearAllfields = () => {

    setPassValue(1);
    setPassPrice(1);
    setExpireDate("");
    setFreePass(false);
  } 

  const handleDelete = (id) => {
      CommonApis.deletePass(id)
      .then(res => {
          if(res.status === 204){
              message.success('social pass successfully deleted.')
              const dataSource = [...dataset];
              setDataset(dataSource.filter(item => item.id !== id))
              
          } else {
            message.error('error deleting social pass !')
          }
      })
      .catch(err => {
        message.error("error deleting pass !")
      })
  }
  const onDateChanged = (date, dateString) => {
    console.log(date, dateString);
    setExpireDate(dateString);
  };

  const columns = [
    {
      title: "",
      dataIndex: "platform",
      key: "id",
      width: "2%",
      render: (theImageURL) => (
        <Avatar
          src={
            process.env.PUBLIC_URL +
            "/images/ic_round_" +
            theImageURL +
            "_logo.png"
          }
        />
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: "Pass Objective",
      dataIndex: "objective",
      key: "objective",
    },
    {
      title: "Pass Value",
      dataIndex: "passValue",
      key: "passValue",
    },

    {
      title: "Pass Price",
      dataIndex: "passPrice",
      key: "passPrice",
      render: (record) =>
        record !== "$0" ? (
          <Badge color={"green"} text={record} />
        ) : (
          <Badge color={"red"} text="Free" />
        ),
    },
    {
      title: "Actions",
      key: "operation",
      render: (record) => 
      dataset.length >= 1 ? (
       <div>
           <Popconfirm title="Sure to update?" onConfirm={() => buildUpdateModal(record)}>
              <a>Update</a>
            </Popconfirm>
            <Popconfirm  title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
              <a style={{marginLeft: "10px", color: "red"}}>Delete</a>
            </Popconfirm>
       </div>
      ) : null,
    },
  ];
  return (
    <div>
      <Table
        
        columns={columns}
        dataSource={dataset}
        rowKey="id"
      />

      <Modal
        title="Update Social Pass"
        visible={showUpdateModal}
        onOk={handleUpdatePassOk}
        confirmLoading={modalLoading}
        onCancel={handleCancel}
      >
        <p>Pass Value: (ex: 100 Likes)</p>
        <InputNumber
          min={0}
          value={passValue}
          defaultValue={0}
          onChange={handlePassValueChange}
        /> <br/><br/>
        <p>Pass Price: (ex: $10)</p>
        <InputNumber
          min={0}
          max={999}
          value={passPrice}
          disabled={inputPriceDisabled}
          defaultValue={passPrice}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          onChange={handlePassPriceValueChange}
        />
        <br /><br/>
        <Checkbox checked={freePass} onChange={onFreePassChange}>Free Pass &#160;</Checkbox>
        <Tooltip title="Free pass requires an expiration date">
          <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
        </Tooltip>

        {showDate && (
          <div style={{ marginTop: "15px" }}>
            <b />
            <b />
            <p>Select pass expiration date:</p>
            <DatePicker onChange={onDateChanged}
             defaultValue={moment(expireData)} />
            <p>
              <Text type="secondary">
                *When this date is expired this pass will disappear from
                LikeStory android app.
              </Text>
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
}
