import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import CommonApis from "../api/CommonApis";
import "./styles.css";
import "antd/dist/antd.css";

function Login(props) {
  useEffect(() => {
    checkLogin();
  });

  const checkLogin = () => {
    if (CommonApis.isAuthenticatedUser()) {
      props.history.push("/home");
    }
  };

  const [loading, setLoading] = useState(false);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 20 }
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 20 }
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = values => {
    setLoading(true);
    console.log("Success:", values);
    console.log("username:" + values.username);
    console.log("password:" + values.password);

    admin.username = values.username;
    admin.password = values.password;

    CommonApis.login(admin)
      .then(res => {
        setLoading(false);
        if (res.data === null || !res.data.successfulLogin) {
          message.error(res.data.errorException);
        }
        if (res.data.successfulLogin) {
          message.success("login successful");
          CommonApis.saveSuccessLogin(res.data.loggedUser, res.data.token);
          props.history.push("/home");
        }
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        message.error("something went wrong !");
      });
  };

  const admin = {
    id: 0,
    username: "",
    password: ""
  };

  return (
    <div className="login-body">
      <div className="login-container">
        <div className="login-form">
          <img
            className="logo-size"
            src={process.env.PUBLIC_URL + "/images/login-logo.png"}
            alt="logo"
          />
          <br></br>
          <Form
            className="login-form-inputs"
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" }
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                className="login-form-button"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
