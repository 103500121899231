import React, {Component} from 'react'

class Error404 extends Component {
    render() {
        return (
            <div>
                <img src="https://colorlib.com/wp/wp-content/uploads/sites/2/404-error-page-templates.jpg" 
                alt="404 not found"
                onClick={() => this.props.history.push('/home')}/>
            </div>
        )
    }
}

export default Error404