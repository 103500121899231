import React, { useState, useEffect } from "react";
import { Spin, Typography, Tooltip, Button, Row, Col, Modal, Radio, Input, message } from "antd";
import { AimOutlined, InfoCircleOutlined } from "@ant-design/icons";

import Cards from "../cards/Cards";
import UsersData from "../tableData/UsersData";
import CommonApis from "../api/CommonApis";
import "./styles.css";

export default function Dashboard() {
  const { Title } = Typography;
  const [dashboardData, setDashboardData] = useState([]);

  // cards data
  const [totalLikes, setTotalLikes] = useState(0);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [showDirectModal, setShowDirectModal] = useState(true);
  const [directModalLoading, setDirectModalLoading] = useState(false);
  const [profileValue, setProfileValue] = useState('');
  const [groupValue, setGroupValue] = useState('email');

  useEffect(() => {
    loadDashboard();
  }, []);

  const loadDashboard = () => {
    setLoadingData(true);
    CommonApis.getDashboard()
      .then((res) => {
        if (res.data === null) {
          console.log("error loading data!");
        }
        //console.log(JSON.stringify(res.data));
        //console.log(JSON.stringify(res.data.users));
        setTotalLikes(res.data.totalLikes);
        setTotalPurchases(res.data.totalPurchases);
        setTotalUsers(res.data.totalUser);

        // dashboardData.fill(res.data.users);

        // console.log(dashboardData);
        // setLoadingData(false);

        let index = 0;
        res.data.users.map((user) => {
          dashboardData[index] = user;
          // console.log(`index[${index}], ${JSON.stringify(dashboardData[index])}`)
          index++;
        });
        console.log(dashboardData);
        setLoadingData(false);
      })
      .catch((err) => {
        console.log("error: " + err.response);
        setLoadingData(false);
        /*
            if(err.response.status !== null){
              if(err.response.status === 401){
              // unauthorized
              // send to login again
             // CommonApis.logout()
             // props.history.push('/login')
            }
            }*/
      });
  };

  const handleDirectUserLoader = () => {
    setShowDirectModal(true)
  }

  const handleDirectLoaderOk = () => {
    if (profileValue === '') {
      message.error("Email or ID required");
    }

    if (groupValue === "email") {
      CommonApis.getUserId(profileValue)
      .then((res) => {
        console.log(res.data);
        setDirectModalLoading(false);
        setShowDirectModal(false);

        window.open(`/user/details/${res.data.userId}/`, '_blank').focus();
      
      })
      .catch((err) => {
        console.log(err);
        setDirectModalLoading(false);
    //    setShowPostCreationModal(false);

        message.error("User not found !");
      });
    }
    if (groupValue === "userId") {
      window.open(`/user/details/${profileValue}/`, '_blank').focus();
    }
  }

  const handleDirectCancel = () => {
    setShowDirectModal(false)
  }

  const onGroupChange = (val) => {
    setGroupValue(val.target.value);
  }

  const handleValueChange = (val) => {
    setProfileValue(val.target.value);

  }
  return (
    <div>
      <Title>Overview &#38; Users</Title>
      <div
        className="site-layout-background"
        style={{ padding: 24, textAlign: "center" }}
      >
        <Cards
          totalUsers={totalUsers}
          totalPurchases={totalPurchases}
          totalLikes={totalLikes}
        />
      </div>
      <Spin spinning={loadingData}>
        <div className="data-table">
        <Row className="social-pass-size">
          <Col span={12}>
          <p>
            Tablel of users: &#160;
            <Tooltip title="click the Search icon to search for users">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          </p>

          </Col>
          <Col span={12}>
          <Tooltip title="Direct Profile Loader">
              <Button
              className="social-apps-add-button"
                type="primary"
                shape="round"
                size="middle"
                onClick={handleDirectUserLoader}
                icon={<AimOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
          
          

          {!loadingData && (
            <UsersData data={dashboardData} handleParentFun={loadDashboard} />
          )}
        </div>
      </Spin>

      <Modal
              title="Direct Profile Loader"
              visible={showDirectModal}
              onOk={handleDirectLoaderOk}
              confirmLoading={directModalLoading}
              onCancel={handleDirectCancel}
            >

            <div>
              <Radio.Group defaultValue="email" size="large" onChange={onGroupChange}>
                <Radio.Button value="email">Email</Radio.Button>
                <Radio.Button value="userId">User ID</Radio.Button>
              </Radio.Group>

              <br />
              <br />
              <p>Email or User ID:</p>
              <Input placeholder="Paste email or ID here..." onChange={handleValueChange} />
            </div>

      </Modal>
    </div>
  );
}
