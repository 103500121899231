import React, { useEffect, useState } from "react";
import { Table, Typography, Spin, message, Tooltip, Popconfirm } from "antd";
import { DownloadOutlined, IssuesCloseOutlined} from "@ant-design/icons";
import CommonApis from "../api/CommonApis";
import {API_BASE_URL} from "../../constants/Constants"
export default function ReleaseData(props) {
  const { Title } = Typography;

  const [data, setData] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState();
  const [activeReleaseCode, setActiveReleaseCode] = useState(0);

  useEffect(() => {
      setData(props.data)
      console.log(data);
      data.map(rel => {
        if (rel.live === true){
            setActiveReleaseCode(rel.versionCode)
            console.log("-> selected vCode: " + activeReleaseCode)
        }
      })
  })
  const columns = [
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packagename",
    },
    {
      title: "Version Code",
      dataIndex: "versionCode",
      key: "versiocode",
      render: (record) => <b>{record}</b>
    },
    {
      title: "Version Name",
      dataIndex: "versionName",
      key: "versionName",
    },
    {
      title: "Target SDK",
      dataIndex: "targetVersion",
      key: "targetversion",
    },
    {
      dataIndex: "versionCode",
      key: "downloadlink",
      render: (record) => (<Tooltip title="Download this version">
        <DownloadOutlined onClick={() => openInNewTab(`${API_BASE_URL}/public/getByVersionCode/${record}`)}/></Tooltip>)
    },
    {
      key: "downloadlink",
      render: (record) => record.live ? (
        <Tooltip title="Make it required !">
          <Popconfirm
            title="Flag as required ?"
            onConfirm={() => handleRequirement(record.versionCode)}>
            <IssuesCloseOutlined />
          </Popconfirm>
        </Tooltip>
      ) : <div></div>
    }
  ];

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleRequirement = (vCode) => {
    console.log("versionCode -> " + vCode);
    setIsLoadingData(true);
    CommonApis.setRequired(vCode)
      .then((res) => {
        console.log("resp : " + res.data.status);
        if(res.data.status === "pass") {
          const thisPorps = {...props};
          thisPorps.handleParentFun()
          message.success(`${vCode} is NOW required.`)
        } else if (res.data.status === "fail") {
          message.error(res.message)
        } else {
          message.warning('Something wrong happend !')
        }
        setIsLoadingData(false);
      })
      .catch(err => {
        console.log(err)
        setIsLoadingData(false);
        message.error('error while updating release !')
      })
  }
  const switchRelease = (vCode, vName) => {
    const thisPorps = {...props};
    setIsLoadingData(true);
    CommonApis.switchRelease(vCode)
      .then((res) => {
     //   console.log(res)
        thisPorps.handleParentFun()
        setIsLoadingData(false);
        message.success('Release successfully switched to version: ' + vName)
      })
      
      .catch((err) => {
        console.log(err)
        setIsLoadingData(false);
        message.error('error switching release.')
      });
  };
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `->onChange selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );

      if (selectedRows[0].versionCode === selectedRowIndex) {
        console.log("release already selected");
        return;
      }
      //   setIsLoadingData(true)
      switchRelease(selectedRows[0].versionCode, selectedRows[0].versionName);
    
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      setSelectedRowIndex();
      setSelectedRowIndex(record.versionCode);
      console.log(`-> onSelect ${selected}, ${selectedRows}, `);
    },
    getCheckboxProps: (record) => {
      console.log("-> props" + record);
    },
  };

  return (
    <div>
      <Spin spinning={isLoadingData}>
        <br />
        
        <Table
          rowSelection={{
            type: "radio",
            ...rowSelection,
            selectedRowKeys: [activeReleaseCode],
          }}
          columns={columns}
          dataSource={data}
          rowKey="versionCode"
        />
      </Spin>
    </div>
  );
}
