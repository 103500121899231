
let BASE_LOCALHOST = "http://localhost:8080"
let BASE_PRODUCTION = "https://api.likestoryeg.com/v2"
let BASE_CLOUD = "https://staging.cloudapi.likestoryeg.com"

// for local or production variable
let isLocal = false;
let isCloud = true;

export const API_BASE_URL = isLocal ? BASE_LOCALHOST : (isCloud ? BASE_CLOUD : BASE_PRODUCTION)


export const SESSION_USER_NAME = 'authUser'
export const SESSION_TOKEN = 'userToken'