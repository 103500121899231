import React, { useState, useEffect } from "react";
import {
  Badge,
  Avatar,
  Button,
  Table,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Typography,
  message,
} from "antd";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import CommonApis from "../api/CommonApis";

export default function ArchivedData(props) {
  const { Option } = Select;
  const { Title } = Typography;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [showProfileFuns, setShowProfileFuncs] = useState(false);
  const [postStatus, setPostStatus] = useState(true);
  const [postObjective, setPostObjective] = useState("");
  const [currentPost, setCurrentPost] = useState({});

  


  const columns = [
    {
      title: "Objective",
      dataIndex: "objective",
      key: "objective",
      width: "2%",
      render: (objective) => (
        <Avatar
          size={48}
          src={process.env.PUBLIC_URL + "/images/" + objective + ".png"}
          alt={objective}
        />
      ),
    },
    {
      title: "Post Url",
      dataIndex: "link",
      key: "link",
      render: (link) => (
        <a href={link} target="_blank">
          {link}
        </a>
      ),
    },
    {
        title: "Date Archived",
        dataIndex: "archivedAt",
        key: "archivedat",
    },
    {
      title: "Objective reach",
      key: "max",
      render: (text, record, index) => (
        <span>{record.objectiveCount + "/" + record.maxCount}</span>
      ),
    }
  ];

  return (
    <div>
      <Table columns={columns} dataSource={props.data} rowKey="userId" />
    </div>
  );
}
