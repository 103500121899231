import React, {useState, useEffect} from 'react';
import {
  Typography,
  Button,
  Tooltip,
  Row,
  Col,
  Modal,
  Select,
  message,
  Input,
  Spin,
  Switch
} from "antd";
import {
  AppstoreAddOutlined,
  ReloadOutlined,
  CheckOutlined,
  CloseOutlined
} from "@ant-design/icons";
import CommonApis from "../api/CommonApis";
import AdsData from '../tableData/AdsData';
export default function Ads () {

  const { Title, Text } = Typography;
  const { Option } = Select;

  const [adsSizeText, setAdsSizeText] = useState('loading ...')
  const [ads, setAds] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [adBanner, setAdBanner] = useState('');
  const [adInterstitial, setAdInterstitial] = useState('');
  const [adNative, setAdNative] = useState('');
  const [adPaused, setPausedAd] = useState(false);

    useEffect(() => {
        loadAds();
      }, []);

      const buildCreationModal = () => {
          setShowCreationModal(true)
      }

      const onRefresh = () => {
          loadAds();
      }

      const handleNewAdOk = () => {
        if (!adPaused) {
          if (adBanner === '') {
            message.warning("Please provide banner Ad !")
            return;
          }
          if (adInterstitial === '') {
            message.warning("Please provide interstitial Ad !")
            return;
          }
        }
        setModalLoading(true);


        let adModal = {
          packageName : 'com.likestoryeg.android',
          paused: adPaused,
          serviceName: 'admob',
          bannerAd : adBanner,
          interstitialAd : adInterstitial,
          nativeAd : adNative
        }

        CommonApis.adNewAd(adModal)
          .then(res => {
            setModalLoading(false);
            setShowCreationModal(false);
            message.success("Ad created successfully !");
            clearAllFields();
            loadAds();
          })
          .catch(err => {
            console.log(err);
            setModalLoading(false);
            setShowCreationModal(false);

            message.error("Error creating Ad !");
          })

      }

      const handleCancel = () => {
        clearAllFields();
        setShowCreationModal(false);
      }

      const onBannerAdChange = (event) => {
          setAdBanner(event.target.value)
      }

      const onInterstitialAdChange = (event) => {
          setAdInterstitial(event.target.value)
      }

      const onNativeAdChange = (event) => {
          setAdNative(event.target.value)
      }

      const onAdSwith = () => {
        setPausedAd(!adPaused)
      }

      const clearAllFields = () => {
        setAdBanner('')
        setAdInterstitial('')
        setAdNative('')
        setPausedAd(false);
      }

      const loadAds = () => {
        setAds([])
        setLoadingData(true);
        CommonApis.getAllAds()
        .then((res) => {
          if (res.data === null) {
            console.log("error loading data!");
          }
          console.log(res.data);
          setAds(res.data)
          setLoadingData(false);
          setAdsSizeText(`${res.data.length} ad found.`)
        })
        .catch((err) => {

        });
    };

      return (
        <div>
          <Title>Manage Ads</Title>
    
          <div>
          <Row className="ads-size">
          <Col span={12}>
            <h3>
              {adsSizeText}
            </h3>
          </Col>
          <Col span={12} style={{marginBottom : '30px'}}>
            <Tooltip title="Add new Ad">
              <Button
                className="social-apps-add-button"
                type="primary"
                shape="circle"
                size="large"
                onClick={buildCreationModal}
                icon={<AppstoreAddOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
    

        <Spin spinning={loadingData}>
          <div className="passes-table-container">
          <Row>
            <Col>
            <Title level={3}>Table of Ads</Title>
            </Col>

            <Col>
            <Tooltip title="reload table of Ads">
              <Button className="social-apps-refresh-button" shape="circle" icon={<ReloadOutlined />} onClick={onRefresh}/>
            </Tooltip>
            </Col>
          </Row>
            {!loadingData && <AdsData data={ads} handleParentFun={loadAds} />}
          <Modal
              title="Add New Ad"
              visible={showCreationModal}
              onOk={handleNewAdOk}
              confirmLoading={modalLoading}
              onCancel={handleCancel}>

                <Row>
                <Col span={10}>
                  <p>Platform:</p>
                  <Select
                    required
                    defaultValue="Admob"
                    style={{ width: "100%" }}>
                    <Option value="admob">Admob</Option>
                    <Option value="facebook" disabled>Facebook Audience Network</Option>
                  </Select>
                </Col>

                <Col span={10} className="modal-right-view">
                  <p>Package Name:</p>
                  <Select
                    defaultValue="com.likestoryeg.android"
                    style={{ width: "100%" }}>
                    <Option value="likestory">com.likestoryeg.adroid</Option>
                  </Select>
                </Col>
                </Row>

              <br />
              <span>Banner ID:</span>
              <br />
              <Input value={adBanner} placeholder="Banner Ad" onChange={onBannerAdChange}/>
              <br />
              <br />
              <span>Interstitial ID:</span>
              <br />
              <Input value={adInterstitial} placeholder="Intertitial Ad" onChange={onInterstitialAdChange} />
              <br />
              <br />
              <span>Native ID:</span>
              <br />
              <Input value={adNative} placeholder="Native Ad" onChange={onNativeAdChange}/>
              <br />
              <br />
              <span>Pause Ads : </span><Switch checked={adPaused} onChange={onAdSwith}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />} />
            
          </Modal>

          </div>

          </Spin>
    </div>
            
    
            
           
        </div>
      );
}
      
