import React from 'react'
import { Card, Col, Row } from "antd";
import {
  DatabaseOutlined,
  DollarCircleOutlined,
  LikeOutlined,
  DashboardOutlined
} from "@ant-design/icons";

export default function UserCards(props) {
    return (
      <div className="site-card-wrapper">
        <Row gutter={16} className="card-row">
          <Col span={6}>
            <Card
              className="cards"
              title="Total Posts"
              bordered={false}
              style={{ width: 200, height: 200 }}
            >
              <DatabaseOutlined style={{ fontSize: "50px" }} />
              <p className="card-result">
                <b>{props.totalPosts}</b>
              </p>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              className="cards"
              title="Current Balance"
              bordered={false}
              style={{ width: 200, height: 200 }}
            >
              <DashboardOutlined style={{ fontSize: "50px" }} />
              <p className="card-result">
                <b>{props.currentBalance}</b>
              </p>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              className="cards"
              title="Total Purchases"
              bordered={false}
              style={{ width: 200, height: 200 }}
            >
              <DollarCircleOutlined style={{ fontSize: "50px" }} />
              <p className="card-result">
                <b>${props.totalPurchases}</b>
              </p>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              className="cards"
              title="Total Likes"
              bordered={false}
              style={{ width: 200, height: 200 }}
            >
              <LikeOutlined style={{ fontSize: "50px" }} />
              <p className="card-result">
                <b>{props.totalLikes}</b>
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
  