import axios from 'axios';
import {SESSION_TOKEN, SESSION_USER_NAME, API_BASE_URL}  from '../../constants/Constants';
class CommonApis {

    login(adminObj){
        return axios.post(`${API_BASE_URL}/auth/adminLogin`, adminObj);
    }

    getDashboard(){
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.get(`${API_BASE_URL}/web/getDashboard`);
    }
    
    getUsers(){
        return axios.get(`${API_BASE_URL}/web/getDashboard`)
    }

    getUserData(userId) {
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.get(`${API_BASE_URL}/web/getUserData/${userId}`);
    }

    getAllLinks(platform) {
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.get(`${API_BASE_URL}/web/getAllLinks/${platform}`);
    }

    getArchivedLinks() {
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.get(`${API_BASE_URL}/web/getArchivedLinks`);
    }

    updateUserPoints(update){
        console.log(`sending update ${JSON.stringify(update)} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.post(`${API_BASE_URL}/web/updateUserPoints`, update);
    }

    updateUserPost(post) {
        console.log(`sending update post: ${JSON.stringify(post)} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.post(`${API_BASE_URL}/web/updatePost`, post)
    }

    addUserPost(post) {
        console.log(`sending new post: ${JSON.stringify(post)} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.post(`${API_BASE_URL}/web/addPost`, post)
    }

    getUserId(email) {
        console.log(`getting user id: ${email} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.get(`${API_BASE_URL}/web/getUserId/${email}`)
    }

    createNewPass(passObj){
        console.log(`sending new pass: ${JSON.stringify(passObj)} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.post(`${API_BASE_URL}/web/createNewPass`, passObj)
    }

    getAllPasses(){
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.get(`${API_BASE_URL}/web/getAllPasses`)
    }

    getReleases(){
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.get(`${API_BASE_URL}/web/getReleases`)
    }

    switchRelease(vCode){
        console.log(`--> switching release to: ${vCode} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.post(`${API_BASE_URL}/web/switchRelease/${vCode}`)
    }

    setRequired(vCode){
        console.log(`--> requiring release: ${vCode} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.post(`${API_BASE_URL}/web/setRequired/${vCode}`)
    }

    updatePass(updatePass){
        console.log(`sending update pass: ${JSON.stringify(updatePass)} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.post(`${API_BASE_URL}/web/updatePass`, updatePass)
    }

    deletePass(id){
        console.log(`deleting passId: ${id} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.delete(`${API_BASE_URL}/web/${id}/deletePass`)
    }

    deletePost(id){
        console.log(`deleting PostId: ${id} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.delete(`${API_BASE_URL}/web/deletePost/${id}`)
    }

    adNewAd(adModal) {
        console.log(`adding new Ad ${JSON.stringify(adModal)} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.post(`${API_BASE_URL}/web/ads`, adModal)
    }

    updateAd(adModal) {
        console.log(`updating Ad ${JSON.stringify(adModal)} ...`)
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.put(`${API_BASE_URL}/web/ads`, adModal)
    }

    getAllAds() {
        console.log("getting ads ...")
        this.setupAxiosInterceptors(this.getUserToken())
        return axios.get(`${API_BASE_URL}/web/ads`)
    }

    saveSuccessLogin(username, token){
        console.log(`logged in as ${username} with token : ${token}`);
        sessionStorage.setItem(SESSION_USER_NAME, username);
        sessionStorage.setItem(SESSION_TOKEN, token);

        // setup interceptors
        this.setupAxiosInterceptors(this.getUserToken())
    }

    isAuthenticatedUser() {
        let isAuthenticated = sessionStorage.getItem(SESSION_USER_NAME);
        if(isAuthenticated === null) {
            return false
        } else {
            return true
        }
    }

    getUserToken(){
        let token = sessionStorage.getItem(SESSION_TOKEN);
        console.log(`user token requested : ${token}`)
        return token
    }

    setupAxiosInterceptors(token){
        axios.interceptors.request.use(
            (config) => {
                if(this.isAuthenticatedUser()){
                    config.headers.authorization = token
                }
                return config;
            }
        )
    }

    logout() {
        sessionStorage.removeItem(SESSION_USER_NAME);
        sessionStorage.removeItem(SESSION_TOKEN);
    }

} 

export default new CommonApis();