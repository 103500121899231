import React, { useState, useEffect } from "react";

import { Spin, Typography, Tooltip, message } from "antd";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";

import Cards from "../cards/Cards";
import UsersData from "../tableData/UsersData";
import CommonApis from "../api/CommonApis";
import "./styles.css";

import UserData from "../tableData/UserData";

export default function AllLinks(props) {
  const { Title } = Typography;

  const [userPosts, setUserPosts] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getAllLinks(props.platform);
  }, [props.platform]);

  const onAllLinksRefersh = () => {
    getAllLinks(props.platform);
    console.log("called from child");
  };

  const getAllLinks = (platform) => {
    console.log("-> getting links for " + platform);
    setLoadingData(true);
    CommonApis.getAllLinks(platform)
      .then((res) => {
        if (res.data === null) {
          message.error("error getting data at this time!");
          return;
        }

        setUserPosts(res.data.posts);
        setLoadingData(false);
      })
      .catch((err) => {
        if (err.response.status !== null) {
          if (err.response.status === 404) {
            // user not found
            // setUserNotFoud(true);
          }
        }
      });
  };

  return (
    <div>
      <Title>All Links</Title>

      <Spin spinning={loadingData}>
        <div className="data-table">
          <p>
            Tablel of links: &#160;
            <Tooltip title="All links are listed here">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          </p>

          {!loadingData && (
            <UserData data={userPosts} handleParentFun={onAllLinksRefersh} />
          )}
        </div>
      </Spin>
    </div>
  );
}
