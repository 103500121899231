import React from "react"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import "./App.css"
import Login from "./components/login/index"
import Error404 from "./components/errors/ErrorPage"
import AuthenticatedRoute from './components/router/AuthenticatedRoute'
import Home from './components/home/index'
import UserPosts from "./components/posts"
function App() {
  return (
    <Router>
      <>
        <Switch>
          <Route path="/" exact component={Login}></Route>
          <Route path="/login" component={Login}></Route>
          <AuthenticatedRoute path="/home" component={Home} />
          <AuthenticatedRoute path="/user/details/:userId" component={UserPosts}></AuthenticatedRoute>
          <Route component={Error404}></Route>
        </Switch>
      </>
    </Router>
  );
}

export default App;
