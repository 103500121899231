import React from "react";
import { Card, Col, Row } from "antd";
import {
  UsergroupAddOutlined,
  DollarCircleOutlined,
  LikeOutlined
} from "@ant-design/icons";

export default function Cards(props) {
  return (
    <div className="site-card-wrapper">
      <Row gutter={16} className="card-row">
        <Col span={8}>
          <Card
            className="cards"
            title="Total Users"
            bordered={false}
            style={{ width: 200, height: 200 }}
          >
            <UsergroupAddOutlined style={{ fontSize: "50px" }} />
            <p className="card-result">
              <b>{props.totalUsers}</b>
            </p>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            className="cards"
            title="Total Purchases"
            bordered={false}
            style={{ width: 200, height: 200 }}
          >
            <DollarCircleOutlined style={{ fontSize: "50px" }} />
            <p className="card-result">
              <b>${props.totalPurchases}</b>
            </p>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            className="cards"
            title="Total Likes"
            bordered={false}
            style={{ width: 200, height: 200 }}
          >
            <LikeOutlined style={{ fontSize: "50px" }} />
            <p className="card-result">
              <b>{props.totalLikes}</b>
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
